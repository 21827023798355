:root {
  /*color*/
  --black: #262121;
  --veryDarkGray: #6d6d6d;
  --darkGray: #a0a0a0;
  --gray: #d9d9d9;
  --lightGray: #fafafa;
  --white: #ffffff;
  --pointPink: #ff005c;
}

/* font */
@font-face {
  font-family: "Pretendard-regular";
  font-weight: 100;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.ttf")
      format("truetype");
  font-display: swap;
  unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: "Pretendard-regular";
  font-weight: 200;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraLight.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraLight.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraLight.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraLight.ttf")
      format("truetype");
  font-display: swap;
  unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: "Pretendard-regular";
  font-weight: 300;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Light.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Light.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Light.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Light.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Light.ttf")
      format("truetype");
  font-display: swap;
  unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: "Pretendard-regular";
  font-weight: 400;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.ttf")
      format("truetype");
  font-display: swap;
  unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: "Pretendard-regular";
  font-weight: 500;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.ttf")
      format("truetype");
  font-display: swap;
  unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: "Pretendard-regular";
  font-weight: 600;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-SemiBold.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-SemiBold.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-SemiBold.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-SemiBold.ttf")
      format("truetype");
  font-display: swap;
  unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: "Pretendard-regular";
  font-weight: 700;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.ttf")
      format("truetype");
  font-display: swap;
  unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: "Pretendard-regular";
  font-weight: 800;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraBold.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraBold.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraBold.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraBold.ttf")
      format("truetype");
  font-display: swap;
  unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: "Pretendard-regular";
  font-weight: 900;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.ttf")
      format("truetype");
  font-display: swap;
  unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 100;
  font-style: normal;
  src: url("./statics/fonts/AppleSDGothicNeoT.ttf") format("truetype");
  font-display: swap;
  unicode-range: U+AC00-D7A3, U+0030-0039, U+3131-3163, U+203B, U+3040-9FFF;
}

@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 200;
  font-style: normal;
  src: url("./statics/fonts/AppleSDGothicNeoUL.ttf") format("truetype");
  font-display: swap;
  unicode-range: U+AC00-D7A3, U+0030-0039, U+3131-3163, U+203B, U+3040-9FFF;
}

@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 300;
  src: url("./statics/fonts/AppleSDGothicNeoL.ttf") format("truetype");
  font-display: swap;
  unicode-range: U+AC00-D7A3, U+0030-0039, U+3131-3163, U+203B, U+3040-9FFF;
}

@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 400;
  src: url("./statics/fonts/AppleSDGothicNeoR.ttf") format("truetype");
  font-display: swap;
  unicode-range: U+AC00-D7A3, U+0030-0039, U+3131-3163, U+203B, U+3040-9FFF;
}

@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 500;
  src: url("./statics/fonts/AppleSDGothicNeoM.ttf") format("truetype");
  font-display: swap;
  unicode-range: U+AC00-D7A3, U+0030-0039, U+3131-3163, U+203B, U+3040-9FFF;
}

@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 600;
  src: url("./statics/fonts/AppleSDGothicNeoSB.ttf") format("truetype");
  font-display: swap;
  unicode-range: U+AC00-D7A3, U+0030-0039, U+3131-3163, U+203B, U+3040-9FFF;
}

@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 700;
  src: url("./statics/fonts/AppleSDGothicNeoB.ttf") format("truetype");
  font-display: swap;
  unicode-range: U+AC00-D7A3, U+0030-0039, U+3131-3163, U+203B, U+3040-9FFF;
}

@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 800;
  src: url("./statics/fonts/AppleSDGothicNeoEB.ttf") format("truetype");
  font-display: swap;
  unicode-range: U+AC00-D7A3, U+0030-0039, U+3131-3163, U+203B, U+3040-9FFF;
}

@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 900;
  src: url("./statics/fonts/AppleSDGothicNeoH.ttf") format("truetype");
  font-display: swap;
  unicode-range: U+AC00-D7A3, U+0030-0039, U+3131-3163, U+203B, U+3040-9FFF;
}

@font-face {
  font-family: "FullAppleSDGothicNeo";
  font-weight: 500;
  src: url("./statics/fonts/AppleSDGothicNeoM.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "FullAppleSDGothicNeo";
  font-weight: 700;
  src: url("./statics/fonts/AppleSDGothicNeoB.ttf") format("truetype");
  font-display: swap;
}

/* Dots */
.slick-dots-custom {
  position: absolute;
  bottom: -25px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots-custom li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.slick-dots-custom li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots-custom li button:hover,
.slick-dots-custom li button:focus {
  outline: none;
}
.slick-dots-custom li button:hover:before,
.slick-dots-custom li button:focus:before {
  opacity: 1;
}
.slick-dots-custom li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: "•";
  text-align: center;

  opacity: 1;
  color: #d9d9d9;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots-custom li.slick-active button:before {
  opacity: 1;
  color: #ff005c;
}

/* .slick-slide {
  padding: 3rem 0;
}

.slider .slick-list {
  margin: -3rem 0;
} */
